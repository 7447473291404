const CONFIG = {
  'local': {
    'debug': true,
    'apiEndpoint': 'http://localhost/api/v1',
  },
  'stage': {
    'debug': true,
    'apiEndpoint': 'https://dashboard.yourvirtualconsult.io/api/v1',
  },
  'prod': {
    'debug': false,
    'apiEndpoint': 'https://dashboard.yourvirtualconsult.com/api/v1',
  }
}

export function getConfig() {
  let env = process.env['REACT_APP_ENV']
  return CONFIG[env]
}

export function getSubdomain() {
  return window.location.host.split('.')[0]
}
