import React from 'react';
import reportWebVitals from './reportWebVitals';
import axios from "axios"

import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
// import your route components too

import { getConfig } from './config'

const Home = () => {
  window.location = "https://yourvirtualconsult.com"
  return (
    <div>Please wait while you a redirected</div>
  )
}

const TrackingLinkRedirect = () => {
  let params = useParams();
  const { guid } = params

  const { apiEndpoint } = getConfig()

  const backend = axios.create({
    baseURL: `${apiEndpoint}`
  })

  backend.get(`/tracking-links/${guid}`)
    .then(response => {
      const { data } = response
      const { url } = data
      const referer = Document.referrer

      let redirectUrl = `${url}?tl=${guid}`
      if (referer) {
        redirectUrl += `&referer=${referer}`
      }

      window.location = redirectUrl
    })
    .catch(error => {
      console.log({error})
    })

  return (
    <div>Redirecting</div>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" >
        <Route index element={<Home />} />
        <Route path="tl/" >
          <Route path=":guid" element={<TrackingLinkRedirect />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
